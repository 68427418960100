import React, { ReactElement } from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { aboutBannerImg, aboutBannerHeight } from "../models/models"

function About(): ReactElement {
  return (
    <>
      <Header page={"About"} />
      <div className="w-full flex flex-col items-center justify-center">
        <div
          className="relative w-full overflow-hidden"
          style={{ height: aboutBannerHeight }}
        >
          <img
            className="inset-0 absolute w-full"
            alt="banner_img"
            src={aboutBannerImg}
          />
        </div>
        <div className="py-2 px-2 md:py-10 md:px-64 text-md md:text-xl text-justify text-gray-700">
          <p className="py-4">
            Brainfull is an Indie Game Development co. for Applications on the
            iPhone, iPad, Mac Appstore, iTunes Appstore, Amazon, Kindle,
            GooglePlay and other Android operating systems.
          </p>
          <p className="py-4">
            We are dedicated to creating Games that are Inviting, Fun,
            Educational, Brain Challenging and an Escape from the Daily Grind!
          </p>
          <p className="py-4">
            Our apps are designed for the young-at-heart, children and adults.
            Brainfull’s games are under various categories from Entertainment to
            Lifestyle, Travel to Puzzles, Word to Hidden Objects, Educational to
            Family, and Simulation to Parenting.
          </p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default About
